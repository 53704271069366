import { Grid, Popup, Tooltip } from '@components';
import { GridRow } from '@components/grid';
import { PageOptions, SortOptions } from '@components/shared';
import FrameElement from '@hotwired/turbo-rails';
import { createElement, stringifyParams, titleCase } from '@utils';
import consumer from '../channels/consumer';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = [
        'navigator',
        'select',
        'actions',
        'search',
        'statusFilter',
        'runDateRange',
        'runDateRangeButton',
        'exportCsvButton',
        'exportCsv',
        'selectAll',
        'confirmActionForm',
        'reportName',
        'billingMethodPopup',
        'gridContainer',
    ];

    declare hasReportNameTarget: boolean;
    declare gridContainerTarget: HTMLElement;
    declare reportNameTarget: HTMLElement;
    declare hasStatusFilterTarget: boolean;
    declare statusFilterTarget: HTMLElement;
    declare navigatorTarget: HTMLAnchorElement;
    declare selectAllTarget: HTMLInputElement;
    declare selectTargets: HTMLInputElement[];
    declare actionsTarget: Element;
    declare hasActionsTarget: boolean;
    declare searchTarget: HTMLInputElement;
    declare runDateRangeButtonTarget: HTMLElement;
    declare runDateRangeTarget: FrameElement;
    declare hasRunDateRangeButtonTarget: boolean;
    declare exportCsvButtonTarget: HTMLElement;
    declare hasExportCsvButtonTarget: boolean;
    declare exportCsvTarget: FrameElement;
    declare confirmActionFormTargets: HTMLInputElement[];
    declare billingMethodPopupTarget: HTMLElement;

    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
    };

    declare urlValue: string;
    private selectedData: GridRow<JournalEntry>[];

    private grid: Grid<JournalEntry>;
    private parmsObj: ParamsObj = {};
    private disabled: boolean;
    private confirmActionFormIdValue: string;
    private entityId: string;
    private canViewConnections: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private jeUpdatesSubscription: any;

    connect(): void {
        if (this.data.get('filter')) {
            this.parmsObj.filter = this.data.get('filter');
        }

        if (this.data.get('status')) {
            this.parmsObj.status = this.data.get('status');
        }

        if (this.data.get('search')) {
            this.parmsObj.search = this.data.get('search');
        }

        if (this.data.get('pg')) {
            this.parmsObj.pg = parseInt(this.data.get('pg'));
        }

        if (this.parmsObj.filter) {
            if (this.hasReportNameTarget) this.reportNameTarget.innerText = this.parmsObj.filter?.replaceAll('_', ' ');
        }

        if (this.data.get('showOnlyRecents') == 'true')
            this.parmsObj.show_only_recents = true;

        this.parmsObj.size = +this.data.get('pageSize') || 50;

        this.entityId = this.element.dataset.orgId;
        this.canViewConnections = this.element.dataset.canViewConnections;

        this.setStatusFilterColor();
        document.addEventListener('grid:closeDateRange', (e) => this.closeDateRange(e));
        document.addEventListener('grid:closeCsvDateRange', (e) => this.closeCsvDateRange(e));

        this.createGrid();

        if (this.entityId) {
            this.jeUpdatesSubscription = consumer.subscriptions.create(
                {
                    channel: 'JournalEntryChannel',
                    org_id: this.entityId,
                },
                {
                    received: this.handleRowUpdates,
                }
            );
        }

        this.reload();
    }

    private handleRowUpdates = (update: JournalEntry) => {
        this.grid.updateRowData(update);
    };

    private createGrid() {
        const element = this.element;
        const edit_je = this.data.get('editJe');
        const rerun_je = this.data.get('rerunJe');
        const repost_je = this.data.get('repostJe');
        const delete_je = this.data.get('deleteJe');
        const isAdmin = this.data.get('isAdmin');
        const importBlockedJeTemplates = JSON.parse(this.data.get('importBlockedJeTemplates')) || [];
        let noRecordsTemplate;
        if (this.data.get('showOnlyRecents') == 'true') {
            noRecordsTemplate = this.noRecentsFoundTemplate;
        } else {
            noRecordsTemplate = this.noRecordsTemplate;
        }
        this.grid = new Grid<JournalEntry>({
            key: 'id',
            allowSelection: true,
            showEmptyState: true,
            noRecordsTemplate: noRecordsTemplate,
            container: this.gridContainerTarget,
            isRowDisabled: (data: JournalEntry): boolean => {
                return (
                    data.aasm_state == 'enqueued' ||
                    data.aasm_state == 'running' ||
                    !data.fully_mapped ||
                    edit_je === 'false'
                );
            },
            columns: [
                {
                    type: 'checkbox',
                },
                {
                    headerTitle: 'Status',
                    width: '128px',
                    field: 'aasm_state',
                    sortable: true,
                    cellTemplate: (entry) => {
                        let classes = 'bg-red-100 text-red-800';
                        switch (entry.aasm_state) {
                            case 'running':
                                classes = 'bg-secondary';
                                break;
                            case 'posted':
                                classes = 'bg-bkgreen';
                                break;
                            case 'enqueued':
                                classes = 'bg-secondary';
                                break;
                            case 'unposted':
                                classes = 'bg-gray-100 text-gray-800';
                                break;
                            case 'deleted':
                                classes = 'bg-bkred-300';
                                break;
                        }
                        return `
                        <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ${classes} uppercase"> 
                            ${entry.aasm_state}
                        </span>
                        `;
                    },
                },
                {
                    headerTitle: 'Date',
                    width: '128px',
                    field: 'date',
                    sortable: true,
                },
                {
                    headerTitle: 'Summary',
                    field: 'short_summary',
                    sortable: true,
                    cellTemplate: (entry: JournalEntry) => {
                        return createElement(`<span class="cursor-pointer">${entry.short_summary}</span>`);
                    },
                    onCellClicked: (rowData: JournalEntry) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).openPopup(`/entities/${this.entityId}/activity/je_view?je_id=${rowData.id}`);
                    },
                    onContextMenu: (rowData: JournalEntry) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).openPopup(`/entities/${this.entityId}/activity/raw?je_id=${rowData.id}`);
                    },
                },
                {
                    headerTitle: 'Posted',
                    width: '120px',
                    field: 'posted',
                    sortable: true,
                    cellTemplate: (data: JournalEntry) => this.createPostedCellTemplate(data),
                },
                {
                    headerTitle: 'Actions',
                    width: '80px',
                    field: '',
                    alignY: 'center',
                    isCellDisabled: (data: JournalEntry): boolean => {
                        const elementData = this.element.dataset;
                        return (
                            ['enqueued', 'running'].includes(data.aasm_state) ||
                            elementData.hasAccountingPlatform == 'false' ||
                            elementData.billingStatus == 'Past Due' ||
                            elementData.billingStatus == 'Suspended' ||
                            edit_je === 'false'
                        );
                    },
                    cellTemplate: (entry) => {
                        const elementData = this.element.dataset;
                        const actions = !entry.qbo_id ? 'Post' : 'Repost';
                        const disabledClass =
                            repost_je === 'false' ||
                            ['enqueued', 'running'].includes(entry.aasm_state) ||
                            elementData.hasAccountingPlatform == 'false' ||
                            elementData.billingStatus == 'Past Due' ||
                            elementData.billingStatus == 'Suspended' ||
                            !entry.fully_mapped ||
                            edit_je === 'false'
                                ? 'opacity-50 cursor-not-allowed'
                                : '';
                        const gridBillingMethodRequired =
                            (elementData.billingMethodRequired == 'true' || elementData.cancelledTrial == 'true') &&
                            disabledClass.length == 0
                                ? true
                                : false;
                        let template: string;
                        let tooltip =
                            elementData.hasAccountingPlatform === 'false'
                                ? 'Connect your accounting platform to perform this action'
                                : entry.fully_mapped
                                ? 'Sync this journal entry with your accounting platform'
                                : 'Please fill all required fields in your mapping to perform this action';
                        if (repost_je === 'false') {
                            tooltip = 'You are not allowed to perform this action';
                            template = `<div class="text-sm text-gray-700 text-center hover:text-bkblue bg-white cursor-pointer ${disabledClass}" data-entry-id="${entry.id}">
                                            ${actions}
                                        </div>`;
                        } else if (gridBillingMethodRequired) {
                            template = `<a class="text-sm text-gray-700 text-center hover:text-bkblue bg-white cursor-pointer ${disabledClass}" data-action="grid#billingMethodRequired" data-entry-id="${entry.id}">
                                            ${actions}
                                        </a>`;
                            tooltip = 'Sync this journal entry with your accounting platform';
                        } else {
                            template = ` 
                                    <a
                                        class="text-sm text-gray-700 text-center hover:text-bkblue bg-white cursor-pointer ${disabledClass}"
                                    >
                                    ${actions}
                                </a>
                            `;
                        }
                        const element = createElement(template);
                        if (tooltip) {
                            new Tooltip({
                                element,
                                content: tooltip,
                                class: 'whitespace-normal',
                            });
                        }
                        return element;
                    },
                    onCellClicked: (data: JournalEntry) => {
                        const billingMethodRequired = this.element.dataset.billingMethodRequired;
                        const cancelledTrial = this.element.dataset.cancelledTrial;
                        const message =
                            billingMethodRequired == 'true'
                                ? 'Please add a payment method to resume posting to your accounting platform.'
                                : `Post Journal Entry for ${data.short_summary}`;
                        if (billingMethodRequired === 'false' && cancelledTrial === 'false' && data.fully_mapped) {
                            const res = confirm(message);
                            if (res) {
                                this.ajax({
                                    url: `/entities/${this.entityId}/activity/repost`,
                                    type: 'POST',
                                    dataType: 'json',
                                    data: stringifyParams({ id: data.id }),
                                    success: (data) => {
                                        if (data) {
                                            this.showAlert(`${data.error}`, { type: 'error' });
                                        }
                                    },
                                });
                            }
                        }
                    },
                },
                {
                    type: 'actions',
                    actions: [
                        {
                            title: 'Refresh from Source',
                            tooltipMessage:
                                rerun_je === 'false'
                                    ? 'You are not allowed to perform this action'
                                    : 'Refresh from source and repost to accounting',
                            onClick: (data: JournalEntry) => {
                                if (
                                    element.dataset.hasBillingToken == 'false' &&
                                    element.dataset.billingStatus != 'Trial'
                                ) {
                                    this.billingMethodRequired();
                                } else {
                                    const message = `Refresh data from ${titleCase(
                                        data.journal_entry_template.split('_')[0]
                                    )}`;
                                    const res = confirm(message);
                                    if (res) {
                                        this.ajax({
                                            url: `/entities/${this.entityId}/activity/rerun`,
                                            type: 'POST',
                                            dataType: JSON,
                                            data: stringifyParams({ id: data.id }),
                                            success: (response) => {
                                                if (response.error) {
                                                    this.showAlert(response.error, { type: 'error' });
                                                } else if (response.success) {
                                                    this.showAlert(response.success, { type: 'success' });
                                                }
                                            },
                                        });
                                    }
                                }
                            },
                            disabled: (entry: JournalEntry) => {
                                return (
                                    !entry.pull_data ||
                                    rerun_je === 'false' ||
                                    entry.aasm_state == 'running' ||
                                    entry.journal_entry_template.includes('zapier') ||
                                    ['Past Due', 'Suspended'].includes(element.dataset.billingStatus) || 
                                    (((entry.journal_entry_template == 'square_deposit' && entry.date < '2023-07-01' && this.grid.entityCreatedDate < '2023-06-20') || importBlockedJeTemplates.includes(entry.journal_entry_template)) && isAdmin === 'false') || (entry.journal_entry_template === 'shopify_cogs' && !(isAdmin == 'true' || entry.user_input_cogs))
                                );
                            },
                            class: (entry: JournalEntry) =>
                                !entry.pull_data ||
                                rerun_je === 'false' ||
                                entry.aasm_state == 'running' ||
                                entry.journal_entry_template.includes('zapier') ||
                                ['Past Due', 'Suspended'].includes(element.dataset.billingStatus) || 
                                (((entry.journal_entry_template == 'square_deposit' && entry.date < '2023-07-01' && this.grid.entityCreatedDate < '2023-06-20') || importBlockedJeTemplates.includes(entry.journal_entry_template)) && isAdmin === 'false') || (entry.journal_entry_template === 'shopify_cogs' && !(isAdmin == 'true' || entry.user_input_cogs))
                                    ? 'cursor-not-allowed'
                                    : '',
                        },
                        {
                            title: 'Delete From Accounting',
                            onClick: (data: JournalEntry) => {
                                if (
                                    element.dataset.hasBillingToken == 'false' &&
                                    !['Trial', 'Live'].includes(element.dataset.billingStatus)
                                ) {
                                    this.billingMethodRequired();
                                } else {
                                    this.showDeletePopup(data);
                                }
                            },
                            disabled: (data: JournalEntry) => {
                                return (
                                    delete_je === 'false' ||
                                    !(
                                        data.aasm_state == 'posted' &&
                                        element.dataset.hasAccountingPlatform == 'true' &&
                                        element.dataset.billingStatus != 'Past Due' &&
                                        element.dataset.billingStatus != 'Suspended'
                                    )
                                );
                            },
                            class: (data) =>
                                delete_je === 'false' ||
                                !(
                                    data.aasm_state == 'posted' &&
                                    element.dataset.hasAccountingPlatform == 'true' &&
                                    element.dataset.billingStatus != 'Past Due' &&
                                    element.dataset.billingStatus != 'Suspended'
                                )
                                    ? 'cursor-not-allowed'
                                    : '',
                            tooltipMessage:
                                delete_je === 'true'
                                    ? this.element.dataset.hasAccountingPlatform == 'true'
                                        ? 'Delete entry from accounting platform'
                                        : 'Connect your accounting platform to perform this action'
                                    : 'You are not allowed to perform this action',
                        },
                    ],
                },
            ],
            pageOptions: {
                number: 1,
                size: this.parmsObj.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page),
            onSelectionChange: (selected: GridRow<JournalEntry>[]) => this.selectionChanged(selected),
        });
    }

    private noRecordsTemplate = () => {
        const org_id = this.entityId;
        const edit_je = this.data.get('editActivity');
        let message = '';
        const connections_path =
            this.canViewConnections == 'true'
                ? `<a href="/entities/${org_id}/connections/apps" data-turbo-frame="_top" class="text-blue-700">connected your apps</a>,`
                : '<span>connected your apps</span>,';
        if (this.grid.totalResultsPerTemplate > 0 || edit_je === 'false') {
            message = 'No records to show.';
        } else {
            message = `Nothing yet. Bookkeep will automatically post available sales data overnight. So if you just ${connections_path} check again tomorrow morning! <br> Or you can immediately <a href="https://bookkeep.com/docs/posting-to-accounting/importing-historical-financial-data-to-bookkeep/" data-turbo-frame="_top" class="text-blue-700" target="_blank">import historical entries</a> using the "Import dates" button.`;
        }
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    private noRecentsFoundTemplate = () => {
        const message = 'No journal entries are posted recently.';

        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    private showDeletePopup(data: JournalEntry) {
        const popup = new Popup({
            title: 'Danger zone',
            content: () => {
                const template = `
                    <p class="mb-4 text-gray-400">Deleting a posting from your accounting platform cannot be undone</p>
                    <div class="flex justify-end items-center flex-wrap mt-6">
                        <p class="mb-4 basis-full">Are you sure you want to delete this journal entry from your accounting platform?</p>
                    </div>`;
                return template;
            },
            actions: [
                {
                    label: 'Cancel',
                    type: 'text',
                    onClick: () => {
                        popup.closeContainer();
                    },
                },
                {
                    label: 'Delete entry',
                    type: 'danger',
                    onClick: () => {
                        this.ajax({
                            url: `/entities/${this.element.dataset.orgId}/activity/delete`,
                            type: 'DELETE',
                            dataType: JSON,
                            data: stringifyParams({ id: data.id }),
                            success: (response) => {
                                popup.closeContainer();

                                if (response.error) {
                                    if (response.error.matched_transaction) {
                                        this.showMatchTranscationPopup(data);
                                    } else {
                                        this.showAlert(response.error, { type: 'error' });
                                    }
                                } else if (response.success) {
                                    this.showAlert(response.success, { type: 'success' });
                                }
                            },
                        });
                    },
                },
            ],
            actions_x_align: 'right',
        });
    }

    private showMatchTranscationPopup(data: JournalEntry) {
        const popup = new Popup({
            title: 'Deposit matches bank transaction',
            content: () => {
                const template = `
                <div class="flex flex-col p-4 space-y-6 text-lg">
                    <div>
                    This entry cannot be deleted as the deposit has been matched to a transaction in your banking feed.
                    </div>
                    <div>
                    To delete this entry, first unmatch this deposit in your
                        <a href="https://qbo.intuit.com/app/journal?txnId=${data.qbo_id}" class="text-bkblue font-bold" target="_blank">
                            accounting platform
                            <svg  class="h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve"><path d="M20 18c0 1.103-.897 2-2 2H6c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h7V2H6C3.794 2 2 3.794 2 6v12c0 2.206 1.794 4 4 4h12c2.206 0 4-1.794 4-4v-7h-2v7z" fill="#009bdf" class="fill-000000"/><path d="M14.879 10.535 20 5.415V9h2l-.001-7H15v2h3.586l-5.121 5.121z" fill="#009bdf" class="fill-000000"/></svg>                                                                                
                        </a>
                    </div>
                </div>`;
                return template;
            },
            actions: [
                {
                    label: 'Got it!',
                    type: 'lite',
                    onClick: () => {
                        popup.closeContainer();
                    },
                },
            ],
        });
    }

    createPostedCellTemplate(data: JournalEntry) {
        let template: string;
        const platform = this.data.get('orgPlatform');

        let status: string = data.status ? data.status : 'Status Not Found';
        status = status.replaceAll('"', '&quot;').replaceAll('.', '. ').replaceAll('<', '&lt;').replaceAll('>', '&gt;');
        const btnTemplate = '<button class="p-1 rounded border border-gray-400 focus:outline-none ">';

        const infoSVG = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="#209cee">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>`;
        const alertSVG = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="#ff3860">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>`;

        if (platform && platform != 'quickbooks') {
            if (data.aasm_state == 'unposted' || !data.qbo_id) {
                template = btnTemplate;

                if (['unposted', 'enqueued', 'running'].includes(data.aasm_state)) {
                    template = template + infoSVG;
                } else {
                    template = template + alertSVG;
                }

                template = template + '</button>';
            } else {
                if (data.status == 'success') {
                    template = `<a target="_blank" href="${data.link_data.url}">${data.link_data.view_text}</a>`;
                    status = data.link_data.title;
                } else {
                    template = btnTemplate + infoSVG + '</button>';
                }
            }
        } else if (data.aasm_state == 'unposted') {
            template = btnTemplate + infoSVG + '</button>';
        } else if (!data.qbo_id) {
            if (['enqueued', 'running'].includes(data.aasm_state)) {
                template = btnTemplate + infoSVG + '</button>';
            } else {
                template = btnTemplate + alertSVG + '</button>';
            }
        } else {
            if (data.qbo_id == '-1') {
                template = '<div>';
            } else {
                template = `<div><a href="https://qbo.intuit.com/app/journal?txnId=${data.qbo_id}" bk-tooltip="View journal entry" target="_blank">${data.qbo_id}</a>`;
            }
            if (parseInt(data.qbo_sync_token) > 0) {
                template += ` (${data.qbo_sync_token})`;
            }
            if (data.status != 'success' && data.status != 'success ') {
                template += `<button bk-tooltip="${status}"
                                class="p-1 rounded border border-gray-400 focus:outline-none ">`;
                template += alertSVG + '</button>';
            }
            status = '';
            template += '</div>';
        }

        const element = createElement(template);
        if (status) {
            new Tooltip({
                element,
                content: status,
                class: 'whitespace-normal',
            });
        }
        element.querySelectorAll('[bk-tooltip]').forEach((element: HTMLElement) => {
            new Tooltip({
                element,
                content: element.getAttribute('bk-tooltip'),
                class: 'whitespace-normal',
            });
        });

        return element;
    }

    setStatusFilterColor(): void {
        if (this.hasStatusFilterTarget) {
            const element = this.statusFilterTarget;
            const selectedStatus = element.querySelector('select').value;
            // Can be done better, but hack for tailwind purge (Purge needs to recognize these classes are used)
            const statuses = [
                'aasm-all',
                'aasm-posted',
                'aasm-running',
                'aasm-enqueued',
                'aasm-failed',
                'aasm-unposted',
                'aasm-deleted',
            ];
            statuses.forEach((status) => element.classList.remove(status));
            element.classList.add('aasm-' + selectedStatus);
        }
    }

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.pg = (+this.parmsObj.pg || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.pg = (+this.parmsObj.pg || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }

    clickaction(e: Event): boolean {
        e.preventDefault();
        this.navigatorTarget.href =
            e.type == 'contextmenu'
                ? (e.currentTarget as HTMLElement).dataset.gridContextMenuHref
                : (e.target as HTMLAnchorElement).href;
        this.navigatorTarget.href +=
            '&from=' +
            encodeURIComponent(
                (this.navigatorTarget.closest('turbo-frame') as FrameElement).src.replace('grid', 'logs')
            );
        const prevTurboFrame = this.navigatorTarget.dataset.turboFrame;
        this.navigatorTarget.dataset.turboFrame = 'turbo-popup';
        this.navigatorTarget.click();
        this.navigatorTarget.dataset.turboFrame = prevTurboFrame;
        return false;
    }

    search(val: string): void {
        val = val.trim();
        if (this.parmsObj.search == val || (!this.parmsObj.search && !val)) {
            return;
        }
        this.parmsObj.search = val;
        this.parmsObj.pg = 1;
        this.reload();
    }

    reload(): void {
        let url = this.urlValue || '';
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
            if (this.element.dataset.activityUrl !== undefined) {
                const activity_url = this.element.dataset.activityUrl + '?' + query;
                window.history.pushState({}, '', activity_url);
            }
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                if (data.total_count == 0 && this.grid.pageOptions.number == 1 && !this.parmsObj.search) {
                    this.grid.showEmptyState = true;
                } else {
                    this.grid.showEmptyState = false;
                }
                this.grid.pageOptions.number = this.parmsObj.pg || 1;
                this.grid.totalResultsCount = data.total_count;
                this.grid.totalResultsPerTemplate = data.total_count_per_template;
                this.grid.entityCreatedDate = data.entity_created_date;
                this.grid.data = data.data;
            },
        });
    }

    pageSize(e: Event): void {
        this.parmsObj.pg = 1;
        e.preventDefault();
        this.reload();
    }

    resync(e: Event): void {
        if (!confirm('Are you sure you want to sync the selected items?')) {
            return;
        }
        const selectedIds = this.selectedData.filter((x) => x.data).map((x) => x.data.id);
        const target = e.currentTarget as HTMLButtonElement;
        target.disabled = true;
        fetch('activity/multipost', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                je_ids: selectedIds,
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).then((res: any) => {
            if (parseInt((res.status / 100).toString()) == 2) {
                this.showAlert('Journal Entries sync started!');
            } else if (res.status == 400) {
                this.showAlert('Connect your accounting platform to perform this action', { type: 'error' });
            }
            this.grid.resetSelection();
            target.disabled = false;
            this.actionsTarget.classList.add('hidden');
        });
    }

    selectionChanged(selected: GridRow<JournalEntry>[]): void {
        this.selectedData = selected;
        if (this.hasActionsTarget) {
            const anySelected = selected.length > 0;
            if (anySelected) {
                this.actionsTarget.classList.remove('hidden');
            } else {
                this.actionsTarget.classList.add('hidden');
            }
        }
    }

    searchChange(): void {
        clearTimeout(+this.data.get('searchTimeout'));
        this.resetSelected();

        this.data.set(
            'searchTimeout',
            setTimeout(() => {
                this.search(this.searchTarget.value);
            }, 500).toString()
        );
    }

    hideShowImportButton(filter: string, import_allowed = true): void {
        const buttonClassList = this.runDateRangeButtonTarget.classList;
        const live_templates = JSON.parse(this.runDateRangeButtonTarget.dataset.liveJeTemplates);
        filter.length === 0 || !live_templates.includes(filter) || !import_allowed
            ? buttonClassList.add('hidden')
            : buttonClassList.remove('hidden');
    }

    hideOrShowExportButton(filter: string, export_allowed = true): void {
        if (!this.hasExportCsvButtonTarget)
            return;

        const buttonClassList = this.exportCsvButtonTarget.classList;
        const live_templates = JSON.parse(this.runDateRangeButtonTarget.dataset.liveJeTemplates);
        filter.length === 0 || !live_templates.includes(filter) || !export_allowed
            ? buttonClassList.add('hidden')
            : buttonClassList.remove('hidden');
    }

    filter(e: CustomEvent): void {
        const filter = e.detail.value;
        this.parmsObj.pg = 1;
        this.parmsObj.filter = filter;
        this.resetSelected();
        this.reload();
        this.runDateRangeTarget.classList.add('hidden');
        this.exportCsvTarget.classList.add('hidden');
        if (this.hasRunDateRangeButtonTarget) {
            this.ajax({
                url: `/entities/${this.entityId}/activity/check_run_date_range`,
                type: 'POST',
                dataType: 'json',
                data: stringifyParams({
                    filter: filter,
                }),
                complete: (data) => {
                    const res = JSON.parse(data.response);
                    if (res.error) {
                        this.hideShowImportButton(filter);
                        this.hideOrShowExportButton(filter);
                    } else {
                        this.hideShowImportButton(filter, res.import_allowed);
                        this.hideOrShowExportButton(filter, res.export_allowed);
                    }
                },
            });
        }
        if (this.hasReportNameTarget) this.reportNameTarget.innerText = filter.replaceAll('_', ' ');
    }

    statusFilter(e: CustomEvent): void {
        const status = e.detail.value;
        this.parmsObj.pg = 1;
        this.parmsObj.status = status;
        this.setStatusFilterColor();
        this.resetSelected();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort;
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.pg = page.number;
        this.parmsObj.size = page.size;
        this.reload();
    }

    runDateRange(e: Event): void {
        const filter = encodeURIComponent(this.parmsObj.filter);
        const url = (e.currentTarget as HTMLElement).dataset.href + '?filter=' + filter;
        this.runDateRangeTarget.src = url;
        this.runDateRangeTarget.classList.remove('hidden');
    }

    exportCsv(e: Event): void {
        const filter = this.parmsObj.filter;
        const url = (e.currentTarget as HTMLElement).dataset.href + '?filter=' + filter;
        this.exportCsvTarget.src = url;
        this.exportCsvTarget.classList.remove('hidden');
    }

    closeDateRange(e: Event): void {
        e.preventDefault();
        this.runDateRangeTarget.classList.add('hidden');
    }

    closeCsvDateRange(e: Event): void {
        e.preventDefault();
        this.exportCsvTarget.classList.add('hidden');
    }

    // BKAPP-251, BKAPP-194
    handleDeleteConfirm(): void {
        for (let i = 0; i < this.confirmActionFormTargets.length; i++) {
            const form = this.confirmActionFormTargets[i];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((form.id as any).value === this.confirmActionFormIdValue) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (form as any).submit.click();
                this.confirmActionFormIdValue = '';
            }
        }
    }

    setconfirmActionFormData(e: Event): void {
        this.confirmActionFormIdValue = (e.target as HTMLElement).getAttribute('data-form-id');
    }

    handleCancelDelete(): void {
        this.confirmActionFormIdValue = '';
    }

    billingMethodRequired(e?: Event): void {
        this.sendEvent(this.billingMethodPopupTarget, 'billingMethodRequired');
        e?.preventDefault();
    }

    startPaidService(e?: Event): void {
        this.sendEvent(this.billingMethodPopupTarget, 'billingMethodRequired');
        e?.preventDefault();
    }

    resetSelected(): void {
        this.grid.resetSelection();
    }

    disconnect() {
        this.jeUpdatesSubscription?.unsubscribe();
        super.disconnect();
    }
}

interface ParamsObj {
    size?: number;
    search?: string;
    filter?: string;
    status?: string;
    sort?: SortOptions;
    pg?: number;
    show_only_recents?: boolean;
}

interface JournalEntry {
    short_summary: string;
    date: string;
    aasm_state: string;
    id: number;
    bk_external_id: string;
    bk_organization_id: string;
    status: string;
    qbo_id: string;
    qbo_sync_token: string;
    pull_data: boolean;
    fully_mapped: boolean;
    journal_entry_template: string;
    user_input_cogs: boolean;
    link_data?: {
        url: string;
        title: string;
        view_text: string;
    };
}
